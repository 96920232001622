import React, { Component } from "react";

class Faq extends Component {
  render() {
    return (
      <div className="faq-wrapper">
        <h2>For Customers</h2>
        <h3>What is Save Your Style?</h3>
        <p>Save Your Style is a directory of local Columbus barber shops and hair salons
        that offer gift card or prepayment options for their services. We hope that with
        the help of this resource, loyal customers will help to support their favorite
        stylists during these turbulent times.
        </p>
        <h3>Why isn’t my favorite business on your site?</h3>
        <p>Help us get your barber on the map by filling out this <a href="https://teamsaveyourstyle.typeform.com/to/qyLk5C">form</a>!
        </p>
        <h3>Why is this just for Columbus? Can you do this for my city?</h3>
        <p>We've only recently put this idea together - if it catches on then we’ll expand to other cities.
        </p>
        <h3>Who built this?</h3>
        <p>We’re two Ohio State students who hope to help out the community!
        </p>

        <h2>For Businesses</h2>
        <h3>Why isn’t my business showing up in your search results?</h3>
        <p>We’re not sure which shops offer gift cards/prepayments - fill you this <a href="https://teamsaveyourstyle.typeform.com/to/qyLk5C">form</a> to let us know!
        </p>
        <h3>How can I start offering online gift cards?</h3>
        <p>The first step is to check with your POS provider. Many offer their own gift card features
        (e.g. Square, Toast, ShopKeep), and others integrate with specific third-party providers.
        If your POS provider doesn’t offer gift cards or integrate with third-party providers,
        there are some reasonable standalone eGift Card apps like GiftUp or GiftFly. If you’re
        considering other options, make sure that your business receives the fee for the gift card
        as soon as the customer purchases the card (otherwise that won’t help you during the crisis). Some
        services may also ask customers to pay an additional fee when they buy a gift
        card but be sure it’s a small amount.
        </p>
        <h3>How can I encourage customers to buy gift cards?</h3>
        <p>Reach out to your network -  both your existing customers as well as those in your
        community! People are looking for ways they can support their favorite businesses,
        so don’t be afraid to let them know that gift cards will help.
        </p>
      </div>
    );
  }
}

export default Faq;
