import React, { Component } from 'react';
import { Route, Link, Switch, withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';

// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/firestore";

import Home from './components/Home';
import Share from './components/Share';
import Faq from './components/Faq';
import Modal from './components/Modal';

import './styles/main.css';

// TODO: Replace the following with your app's Firebase project configuration
let firebaseConfig = {
  apiKey: "AIzaSyBrhPUE3FDiNfkwJV7j8h7LvhJIm826HX8",
  authDomain: "saveyourstyle-84e1b.firebaseapp.com",
  databaseURL: "https://saveyourstyle-84e1b.firebaseio.com",
  projectId: "saveyourstyle-84e1b",
  // storageBucket: "project-id.appspot.com",
  // messagingSenderId: "sender-id",
  // appId: "app-id",
  // measurementId: "G-measurement-id",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

class App extends Component {
  constructor(props){
    super(props);
    this.previousLocation = this.props.location;
  }

  componentWillUpdate() {
    let { location } = this.props;

    if (!(location.state && location.state.modal)) {
      this.previousLocation = location;
    }
  }

  render() {
    console.log(this.state)
    const { location } = this.props;
    const isModal = (
      location.state &&
      location.state.modal &&
      this.previousLocation !== location
    );

    return (
      <div className="app">
        <CssBaseline />
        <AppBar position="relative" elevation={0}>
          <Toolbar className="menu">
            <Button color="inherit"><Link className="menu-button" to='/'>Home</Link></Button>
            <Button color="inherit"><Link className="menu-button" to='/faq'>FAQ</Link></Button>
            <Button color="inherit"><Link className="menu-button" to='/share'>Share</Link></Button>
          </Toolbar>
        </AppBar>
        <Switch location={isModal ? this.previousLocation : location}>
          <Route exact path="/" component={Home} />
          <Route exact path="/faq/" component={Faq} />
          <Route exact path="/share" component={Share} />
          <Route exact path="/modal/:id"><Modal isModal={isModal}/></Route>
          <Route>{'no match sorry'}</Route>
        </Switch>
        {isModal
          ? <Route exact path="/modal/:id"><Modal isModal={isModal}/></Route>
          : null
        }
      </div>
    );
  }
}

export default withRouter(App);
