import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { styled } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import SearchBar from 'material-ui-search-bar';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import logo from './logo_5.png';
import * as firebase from "firebase/app";

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

const StyledTypography = styled(Typography)({
  color: 'white',
});

const FooterTypography = styled(Typography)({
  color: 'white',
});

const StyledButton = styled(Button)({
  border: '1px solid #ffffff',
  borderRadius: 3,
  color: 'white',
  height: 48,
  padding: '0 30px',
});

const StyledSecondaryButton = styled(Button)({
  backgroundColor: '#6B9362',
});

const StyledLink = styled(Link)({
  color: 'white',
  textDecoration: 'none',
});

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = { businesses: [], business_names: [], filtered_businesses: [] };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = () => {
    let db = firebase.firestore();
    let component = this
    db.collection("businesses").get().then(function(querySnapshot) {
      let queryResult = querySnapshot.docs.map(b => b.data());
      let names = queryResult.map(b => b.name);
      component.setState({ businesses: queryResult,
                           filtered_businesses: queryResult,
                           business_names: names
                         })
    });
  }

  handleChange(text) {
    let res = [];
    this.state.businesses.forEach((item, i) => {
      if (item.name.toLowerCase().includes(text.toLowerCase())) {
        res.push(item);
      }
    });
    this.setState({
      filtered_businesses: res
    });
  }

  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <main>
          <div className="heroContent">
            <Container maxWidth="sm">
              {/*<Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                Save Your Fave
              </Typography> */}
              <img src={logo} className="logo"/>
              <StyledTypography variant="h5" align="center" paragraph>
                Help your local barbershops and hair salons by purchasing gift cards
                which help to alleviate their financial burdens during the COVID-19 crisis
              </StyledTypography>
              <div className="heroButtons">
                <Grid container spacing={0} justify="center">
                  <Grid item>
                    <StyledButton href="https://teamsaveyourstyle.typeform.com/to/qyLk5C" size="large" disableElevation>
                      Add a new business
                    </StyledButton>
                  </Grid>
                </Grid>
              </div>
            </Container>
          </div>
          <Container className="cardGrid" maxWidth="md">
            <MuiThemeProvider>
              <SearchBar
                onChange={(value) => this.handleChange(value)}
                //onRequestSearch={() => console.log('onRequestSearch')}
                dataSource={this.state.business_names}
                style={{
                  maxWidth: '100%',
                  marginBottom: '50px'
                }}
              />
            </MuiThemeProvider>

            {/* End hero unit */}
            <Grid container spacing={4}>
              {this.state.filtered_businesses.map((business) => (
                <Grid item key={business.id} xs={12} sm={6} md={4}>
                  <Card className={"card"}>
                    <CardMedia
                      className="cardMedia"
                      image={business.image_url}
                      title="Image title"
                    />
                    <CardContent className="cardContent">
                      <Typography gutterBottom variant="h5" component="h2">
                        {business.name}
                      </Typography>
                      <Typography>
                        {business.street_address + ", " + business.city + ", " + business.state + " " + business.zip_code}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <StyledSecondaryButton variant="contained" size="medium" disableElevation>
                        <StyledLink
                          className="frontpage-job"
                          to={{
                            pathname: "/modal/" + business.id,
                            state: { modal: true }
                          }}
                        >
                          Buy Gift Card
                        </StyledLink>
                      </StyledSecondaryButton>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </main>
        {/* Footer */}
        <footer className="footer">
          <FooterTypography variant="h6" align="center">
            Stay safe and wash your hands!
          </FooterTypography>
        </footer>
        {/* End footer */}
      </React.Fragment>
    );
  }
}

export default withRouter(Home);
