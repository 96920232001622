import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons';

import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
} from "react-share";

import * as firebase from "firebase/app";

class ModalBody extends Component {
  constructor(props) {
    super(props);
    this.state = { business: false }
  }

  componentDidMount = () => {
    let db = firebase.firestore();
    let component = this
    db.collection("businesses").where("id", "==", component.props.id).get().then(function(querySnapshot) {
      component.setState({ business: querySnapshot.docs.map(b => b.data())[0] })
    });
  }

  render() {
    if (this.state.business === false) {
      return null
    }
    const {zip_code, street_address, city, web_link, image_url, business_email, message, state, name, giftcard_link, phone_number, options, social_links} = this.state.business;
    return (
      <>
        <div className="modal-image">
          <img className="business-image" src={image_url}></img>
        </div>
        <div className="modal-content">

        {this.props.history &&
          <a onClick={() => this.props.history.goBack()}
             style={{float: 'right', padding: '5px', marginTop: '10px', marginRight: '5px'}}>
            <FontAwesomeIcon className="icon" icon={faTimes} size="lg" />
          </a>
        }

          <div className="modal-content-wrapper">
            <div className="modal-header">
              <h1 className="business-name"><a className="business-title-link" href={web_link}>{name}</a></h1>
              <p className="business-address">{street_address}, {city}, {state}, {zip_code}
                <br/>{phone_number}</p>
            </div>
            <div className="modal-instructions">
              <p>Thanks for helping out! You can purchase gift cards in the following ways:</p>
              <div className="btn-group">
                {options.call_for_giftcard && <button className="buy-btn">Call</button>}
                {options.link_for_giftcard && <a href={giftcard_link}>
                  <button style={{ cursor: 'pointer' }} className="buy-btn">Buy Online</button>
                </a>}
              </div>
            </div>
            <div className="modal-social-media">
              <p>Help spread the word:</p>
              <div className="icon-group">
                <FacebookShareButton url={window.location.href}><FontAwesomeIcon className="share-icon facebook" icon={faFacebook} size="3x" /></FacebookShareButton>
                <TwitterShareButton url={window.location.href}><FontAwesomeIcon className="share-icon twitter" icon={faTwitter} size="3x" /></TwitterShareButton>
                <EmailShareButton url={window.location.href}><FontAwesomeIcon className="share-icon mail" icon={faEnvelope} size="3x" /></EmailShareButton>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default ModalBody;
