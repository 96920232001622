import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

class Share extends Component {
  render() {
    return (
      <div className="share-icon-group">
        <h1>Help spread the word about Save Our Style!</h1>
        <FacebookShareButton url={window.location.hostname}>
          <FontAwesomeIcon className="icon facebook" icon={faFacebook} size="3x" />
        </FacebookShareButton>
        <TwitterShareButton url={window.location.hostname}>
          <FontAwesomeIcon className="icon twitter" icon={faTwitter} size="3x" />
        </TwitterShareButton>
        <EmailShareButton url={window.location.hostname}>
          <FontAwesomeIcon className="icon mail" icon={faEnvelope} size="3x" />
        </EmailShareButton>
      </div>
    );
  }
}

export default Share;
